import React, { useContext, useState, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import { strapiHost, useImagePreloader } from "../../utils";
import {
  styled,
  media,
  fonts,
  angle,
  pink,
  black,
  style,
  width
} from "../../styles";
import { Page, Content, Link } from "../../components";

type Image = {
  height: number;
  width: number;
  url: string;
  alt: string | null;
};

type Article = {
  id: { de: number | null; en: number | null };
  title: string;
  slug: any;
  published: string;
  html: any;
  image: Image;
};

const NewsArticle: React.FC<{}> = props => {
  const loadBySlug = useRef();
  const [isEnglish, setIsEnglish] = useState(
    props.location.pathname.includes("/en")
  );
  const [articleData, setArticleData] = useState<Article>({
    id: { de: null, en: null },
    title: "",
    slug: "",
    published: "",
    html: "",
    image: {
      height: 0,
      width: 0,
      url: "",
      alt: ""
    }
  });
  const articleId = props.location.state?.articleId;
  const { preloadImages, pending } = useImagePreloader();
  // const slug = props.params.slug;
  // console.log({ slug });
  // console.log({ articleId });

  if (typeof window === "undefined") {
    // Server-side: during build
    return <div>Loading...</div>;
  }

  if (!articleId) {
    // Client-side: handle the missing articleId case
    const path = props.location.pathname;
    loadBySlug.current = path.slice(path.lastIndexOf("/") + 1);
    // navigate("/");
    // return null;
  }

  async function fetchArticleData() {
    let response = null;
    // get the articles smaller image if present
    const getArticleImage = (image: any): Image => {
      let img = { height: 0, width: 0, url: "", alt: "" };
      if (image?.data?.attributes?.formats?.medium) {
        img = {
          height: image.data.attributes.formats.medium.height,
          width: image.data.attributes.formats.medium.width,
          url: strapiHost + image.data.attributes.formats.medium.url,
          alt: image.data.attributes.alternativeText
        };
      } else {
        img = {
          height: image.data.attributes.height,
          width: image.data.attributes.width,
          url: strapiHost + image.data.attributes.url,
          alt: image.data.attributes.alternativeText
        };
      }
      return img;
    };

    // convert published date
    function pubDateConverter(date: string) {
      const tmpDate = new Date(date);
      const dateString = tmpDate.toLocaleDateString("de", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
      return dateString;
    }

    function strToHtml(data: string) {
      const div = document.createElement("div");

      div.innerHTML = data;
      // lets remove styles and replace oembed
      Array.from(div.children).forEach(item => {
        item.removeAttribute("style");
        if (item.innerHTML.trim() === "&nbsp;") {
          item.remove();
        }
        if (item.tagName === "FIGURE") {
          const oEmbed = item.querySelector("oembed");
          const mediaUrl = oEmbed?.getAttribute("url");
          const responsiveVideoContainer = document.createElement("div");
          const iFrame = `
<iframe width="560" height="315" src="${mediaUrl?.replace(
            "watch?v=",
            "embed/"
          )}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          `;
          responsiveVideoContainer.classList.add("responsive-video");
          responsiveVideoContainer.innerHTML = iFrame;
          oEmbed?.remove();
          item.replaceWith(responsiveVideoContainer);
        }
      });

      return div;
    }

    // function getLocalizedProp(isEnglish: boolean, item: any, prop: string) {
    //   if (isEnglish && item.attributes.localizations.data.length) {
    //     return item.attributes.localizations.data[0].attributes[prop];
    //   }
    //   return item.attributes[prop];
    // }

    function isNumber(value: number | undefined): number | null {
      if (typeof value === "number") {
        return value;
      }
      return null;
    }

    function transformFetchData({ data }): Article | undefined {
      if (data) {
        return {
          id: {
            de: isNumber(data.id),
            en: isNumber(data.attributes.localizations?.data?.[0]?.id)
          },
          title: data.attributes.Titel,
          slug: {
            [isEnglish ? "en" : "de"]: data.attributes.Slug,
            [isEnglish ? "de" : "en"]: data.attributes.localizations.data.length
              ? data.attributes.localizations.data[0].attributes.Slug
              : ""
          },
          published: pubDateConverter(data.attributes.PubDate),
          html: strToHtml(data.attributes.Text),
          image: getArticleImage(data.attributes.Bild)
        };
      }

      navigate(isEnglish ? "/en/presse-und-news" : "/presse-und-news");
      return;
    }

    if (loadBySlug.current) {
      if (isEnglish) {
        response = await fetch(
          `${strapiHost}/api/artikels?populate=*&locale[0]=en&filters[Slug][$eq]=${loadBySlug.current}`
        );
      } else {
        response = await fetch(
          `${strapiHost}/api/artikels?populate=*&filters[Slug][$eq]=${loadBySlug.current}`
        );
      }
    } else {
      response = await fetch(
        `${strapiHost}/api/artikels/${articleId}?populate=*`
      );
    }

    // const response = await fetch(
    //   `${strapiHost}/api/artikels/${articleId}?populate=*`
    // );
    const jsonData = await response.json();
    // console.log('json data')
    // console.log(jsonData.data);
    const data = transformFetchData(
      Array.isArray(jsonData.data) ? { data: jsonData.data[0] } : jsonData
    );
    if (data) {
      await preloadImages([data?.image.url]);
      setArticleData(data);
    }
  }

  useEffect(() => {
    fetchArticleData();
  }, []);

  function setInnerHTML(html: any) {
    return {
      __html: html.innerHTML
    };
  }

  return (
    <Page
      title={articleData.title}
      seoTitle="Tonstudio | Musik, Sounddesign & Sprachaufnahmen | German Wahnsinn"
      description="Wir komponieren und vertonen für euch und für alle Werbeagenturen, Filmproduktionen, Verlage, Label und Musiker."
      keywords={[
        "German Wahnsinn",
        "Tonstudio",
        "Musikstudio",
        "Sounddesign",
        "Sprachaufnahmen",
        "Audio",
        "Postproduction",
        "Hörbücher",
        "Hörspiele",
        "Podcasts"
      ]}
      sideLabel={false}
      footerProps={{ isEnglish, articleData }}
      {...props}
    >
      <Content>
        <Loader className={pending ? "" : "hide"}>
          <div className="loader"></div>
        </Loader>
        <ArticleContainer>
          <BackButton>
            <button
              onClick={() =>
                navigate(isEnglish ? "/en/presse-und-news" : "/presse-und-news")
              }
            >
              {isEnglish ? "Go back" : "Hier geht's Zurück"}
            </button>
          </BackButton>
          <FlexContainer>
            <ArticleHeading>{articleData.title}</ArticleHeading>
            <ArticleImage>
              <small>{articleData.published}</small>
              <img
                src={articleData.image.url}
                alt={
                  articleData.image.alt || `Dieses Bild hat keine Beschreibung`
                }
              />
            </ArticleImage>
          </FlexContainer>
          <ArticleContent
            dangerouslySetInnerHTML={setInnerHTML(articleData.html)}
          />
        </ArticleContainer>
      </Content>
    </Page>
  );
};

const Loader = styled.div`
  height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  left: 0;
  user-select: none;
  background-color: #f2f4f7;
  z-index: 10;
  div.loader {
    width: 40px;
    aspect-ratio: 1;
    --c: linear-gradient(#ff0064 0 0);
    --m: radial-gradient(farthest-side, #ff0064 92%, #0000);
    background: var(--m) center / 12px 12px,
      var(--c) left 50% top -20px/8px 16px,
      var(--c) left 50% bottom -20px/8px 16px,
      var(--c) top 50% left -20px/16px 8px,
      var(--c) top 50% right -20px/16px 8px;
    background-repeat: no-repeat;
    animation: l18-1 1.5s infinite, l18-2 1.5s infinite;
  }

  @keyframes l18-1 {
    30%,
    70% {
      background-position: center, left 50% top calc(50% - 8px),
        left 50% bottom calc(50% - 8px), top 50% left calc(50% - 8px),
        top 50% right calc(50% - 8px);
    }
  }
  @keyframes l18-2 {
    0%,
    40% {
      transform: rotate(0);
    }
    60%,
    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes hideLoader {
    0% {
      opacity: 1;
    }
    40%,
    100% {
      opacity: 0;
    }
  }

  @keyframes moveLoader {
    0% {
      height: 100vh;
      width: 100vw;
      left: 0;
    }
    100% {
      height: 0;
      width: 0;
      left: -5000;
    }
  }

  &.hide {
    animation: hideLoader 1.5s ease-in-out 0.75s 1 normal forwards running,
      moveLoader 0.25s ease-in-out 1.5s 1 normal forwards running;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 920px;
  margin: 6rem auto 0;
  .ccm-settings-summoner {
    display: none;
  }
`;

const BackButton = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 0 1rem;
  button {
    cursor: pointer;
    padding: 1rem 1.6rem 1rem 0;
    margin: 0;
    background: transparent;
    color: ${black};
    &:before {
      content: ";";
      margin: 0 8px 0 0;
      font-family: ${fonts.vario};
      text-decoration: none;
      vertical-align: baseline;
      text-transform: uppercase;

      &:hover {
        color: ${pink};
        animation: wahnsinn 0.3s linear 0s infinite;
      }
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.laptop} {
    flex-direction: row-reverse;
    margin-bottom: 1.75rem;
    gap: 2rem;
    h1 {
      flex: 0 1 60%;
      padding-bottom: 3px;
      font-size: 3rem;
      align-self: self-end;
    }
    div {
      flex: 0 1 40%;
    }
  }
`;

const ArticleHeading = styled.h1`
  margin: 0 0 1rem 0;
  font-size: 2rem;
  ${media.tablet} {
    font-size: 2.5rem;
  }
`;

const ArticleImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 0 2rem;
  small {
    font-size: 1.1rem;
  }
`;

const ArticleContent = styled.div`
  h2 {
    font-size: 2rem;
    margin: 3.25rem 0 1.5rem 0;
  }
  h3 {
    font-family: SuperVario;
    font-variation-settings: "wght" 520;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.35rem;
    margin: 2.75rem 0 1.5rem 0;
  }
  .responsive-video {
    position: relative;
    padding-bottom: 49%; /* 16:9 Aspect Ratio */
    padding-top: 25px;
    height: 0;
    margin-bottom: 2rem;
  }
  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ol li, ul li {
    list-style-type: auto !important;
    font-size: 1.25rem;
  }
  ul li {
    list-style-type: initial !important;
    margin: 0 0 0 4rem;
  }
  
  p .text-tiny {
    font-size: 1rem;
  }

  p .text-small {
    font-size: 1.2rem;
  }
  p:has(.text-tiny),
  p:has(.text-small) {
    line-height: 1;
  }

  p .text-big {
    font-size: 2rem;
  }
  p:has(.text-big) {
    line-height: 1.1;
  }

  blockquote {
    padding: 1.2rem 0.75rem;
  }

  blockquote p {
    font-style: italic;
    line-height: 1.15;
    font-size: 1.5rem;
    margin: 0;
  }

  .custom-block-indent-a {
    margin-left: 4rem;
  }

  ${media.tablet} {
  }

  ${media.laptop} {
    .responsive-video {
      padding-bottom: 53.55%;
    }
  }

  ${media.desktop} {
  }
`;

export default NewsArticle;
